@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Graphik', sans-serif;
    scroll-behavior: smooth;
    accent-color: rgb(6 68 65);
  }

  .errorPage {
    background: url('/src/assets/backImage.svg') left no-repeat,
      url('/src/assets/backImage.svg') right no-repeat;
    background-size: 45%, 45%;
  }

  /* width */
  .sidebar::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .sidebar::-webkit-scrollbar-track {
    background: inherit;
  }

  /* Handle */
  .sidebar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  .sidebar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
